import {SystemSuppliedEntityInfo} from "@/services/datatype/entity/SystemSuppliedEntityInfo";
//import DvaUtils from '@/utils/DvaUtils';
import {EntityInfo} from "@/services/datatype/entity/EntityInfo";
import EntityUtils from "@/services/util/EntityUtils";
import {Serializer} from "@/services/serialize/Serializer";
import {qllStorageSession} from "@/services/util/StorageUtils";
import {LOGIN_USER_ENTITY_INFO} from "@/constants/SessionDataKeys";
import {TypeMapper} from "@/services/serialize/TypeMapper";
import {UserRoles} from "@/services/session/UserRoles";
import {UserRole} from "@/services/session/UserRole";
import {UserDetails} from "@/services/datatype/entity/user/UserDetails";
import {Profession} from "@/services/datatype/entity/user/Profession";
import {DefaultWebServiceFactory} from "@/services/webapi/DefaultWebServiceFactory";
import RoleTypeUtils from "@/services/util/RoleTypeUtils";

export default class UserTools {
  private static loginUserEntityInfo: SystemSuppliedEntityInfo | null;
  private static _userRoles: UserRoles;
  private static _currentRole: UserRole;

  static getLoginSystemSuppliedEntityInfo(): SystemSuppliedEntityInfo | null {
    if (!UserTools.loginUserEntityInfo) {
      const entityInfoText = qllStorageSession.getItem(LOGIN_USER_ENTITY_INFO);
      if (entityInfoText) {
        const systemSuppliedEntityInfo = new SystemSuppliedEntityInfo();
        try {
          const jsonObj = JSON.parse(entityInfoText);
          systemSuppliedEntityInfo.fillFromJson(
            jsonObj,
            TypeMapper.getTypeFactory()
          );
          UserTools.setLoginSystemSuppliedEntityInfo(systemSuppliedEntityInfo);
        } catch (ex) {
          console.error(
              "Failed to deserialize message: \n" +
              entityInfoText +
              "\n with error:\n" +
              ex.toString()
          );
        }
      }
    }
    return UserTools.loginUserEntityInfo;
  }

  static getUserName(): string | null {
    let entityInfo: SystemSuppliedEntityInfo = UserTools.getLoginSystemSuppliedEntityInfo();
    if (entityInfo) {
      let entityDesc = entityInfo.getEntityDesc();
      if (entityDesc) {
        return entityDesc.getNickName();
      }
    }
    return null;
  }

  static setLoginSystemSuppliedEntityInfo(
      loginEntityInfo: SystemSuppliedEntityInfo | null
  ): void {
    const currentLogin = !!UserTools.loginUserEntityInfo;
    UserTools.loginUserEntityInfo = loginEntityInfo;
    if (loginEntityInfo) {
      qllStorageSession.setItem(
          LOGIN_USER_ENTITY_INFO,
          Serializer.toJsonString(loginEntityInfo)
      );
      this._userRoles = new UserRoles();
      this._userRoles.entityInfo = loginEntityInfo;
      this._currentRole = this._userRoles.getFirstRole();
      if (!currentLogin) {
        DefaultWebServiceFactory.getInstance()
          .getWebMessageService()
          .checkToResubscribe();
      }
    } else {
      qllStorageSession.removeItem(LOGIN_USER_ENTITY_INFO);
    }
  }

  public static get entityInfo(): EntityInfo | null | undefined {
    const loginSystemSuppliedEntityInfo: SystemSuppliedEntityInfo | null =
      UserTools.getLoginSystemSuppliedEntityInfo();
    if (loginSystemSuppliedEntityInfo) {
      return EntityUtils.getEntityInfoFromSystemSuppliedEntityInfo(
        loginSystemSuppliedEntityInfo
      );
    } else {
      return null;
    }
  }

  public static requireOnLineSession(): boolean {
    const entityInfo = this.getLoginSystemSuppliedEntityInfo();
    if (entityInfo) {
      const additionalAttributes = entityInfo
        .getEntityDesc()
        .getAdditionalAttributes();
      if (additionalAttributes && additionalAttributes instanceof UserDetails) {
        const userDetails = additionalAttributes as UserDetails;
        return userDetails.profession == Profession.LAWYER;
      }
    }
    return false;
  }

  public static getUserId(): number | string | undefined | null {
    const loginSystemSuppliedEntityInfo: SystemSuppliedEntityInfo | null =
      UserTools.getLoginSystemSuppliedEntityInfo();

    if (loginSystemSuppliedEntityInfo) {
      return loginSystemSuppliedEntityInfo.entityDesc?.nid as string;
    } else {
      return null;
    }
  }

  public static get userRoles(): UserRoles {
    UserTools.getLoginSystemSuppliedEntityInfo();
    return this._userRoles;
  }

  public static get currentRole(): UserRole {
    UserTools.getLoginSystemSuppliedEntityInfo();
    return this._currentRole;
  }

  public static isLawyer(): boolean {
    let userRole: UserRole = UserTools.currentRole;
    if (!userRole) {
      let userRoles: UserRoles = UserTools.userRoles;
      if (userRoles) {
        UserTools.currentRole = userRoles.getFirstRole();
      }
    }
    if (userRole) {
      let roleType = userRole.roleType;
      return RoleTypeUtils.isLawyer(userRole.enterpriseInfo.enterpriseType,
          roleType);
    } else {
      return false;
    }
  }

  public static set currentRole(value: UserRole) {
    this._currentRole = value;
  }

  public static get loginId(): number | string | undefined | null {
    return UserTools.getUserId();
  }

  public static get isLogin(): boolean {
    const currentUserId = UserTools.loginId;
    return currentUserId != null && currentUserId !== undefined;
  }

  public static logout(): void {
    UserTools.setLoginSystemSuppliedEntityInfo(null);
  }
}
