/**
 * 自动生成， 请不要手工修改！
 */

import {WebChatServiceImpl} from '../webimpl/WebChatServiceImpl'
import {WebEnterpriseHomeServiceImpl} from '../webimpl/WebEnterpriseHomeServiceImpl'
import {WebEnterpriseServiceOrderServiceImpl} from '../webimpl/WebEnterpriseServiceOrderServiceImpl'
import {WebEnterpriseServicePackageServiceImpl} from '../webimpl/WebEnterpriseServicePackageServiceImpl'
import {WebEnterpriseServiceRequestServiceImpl} from '../webimpl/WebEnterpriseServiceRequestServiceImpl'
import {WebEnterpriseServiceSettlementServiceImpl} from '../webimpl/WebEnterpriseServiceSettlementServiceImpl'
import {WebEntityServiceImpl} from '../webimpl/WebEntityServiceImpl'
import {WebGeneralReviewServiceImpl} from '../webimpl/WebGeneralReviewServiceImpl'
import {WebMessageServiceImpl} from '../webimpl/WebMessageServiceImpl'
import {WebMineServiceImpl} from '../webimpl/WebMineServiceImpl'
import {WebPersonalServiceImpl} from '../webimpl/WebPersonalServiceImpl'
import {WebServiceFactory} from './WebServiceFactory'
import {WebSharedInfoServiceImpl} from '../webimpl/WebSharedInfoServiceImpl'
import {WebSystemServiceImpl} from '../webimpl/WebSystemServiceImpl'
import {WebSharedInfoService} from "@/services/webapi/WebSharedInfoService";
import {WebChatService} from "@/services/webapi/WebChatService";
import {WebEnterpriseServiceOrderService} from "@/services/webapi/WebEnterpriseServiceOrderService";
import {WebPersonalService} from "@/services/webapi/WebPersonalService";
import {WebEnterpriseHomeService} from "@/services/webapi/WebEnterpriseHomeService";
import {WebGeneralReviewService} from "@/services/webapi/WebGeneralReviewService";
import {WebEnterpriseServiceSettlementService} from "@/services/webapi/WebEnterpriseServiceSettlementService";
import { WebMineService } from './WebMineService'
import {WebSystemService} from "@/services/webapi/WebSystemService";
import {WebEntityService} from "@/services/webapi/WebEntityService";
import {WebEnterpriseServiceRequestService} from "@/services/webapi/WebEnterpriseServiceRequestService";
import {WebEnterpriseServicePackageService} from "@/services/webapi/WebEnterpriseServicePackageService";
import { WebMessageService } from './WebMessageService'

export class DefaultWebServiceFactory implements WebServiceFactory {
    static instance: WebServiceFactory = new DefaultWebServiceFactory();

    public static getInstance(): WebServiceFactory {
        return DefaultWebServiceFactory.instance;
    }

    private webEntityService = new WebEntityServiceImpl();
    private webSystemService = new WebSystemServiceImpl();
    private webMineService = new WebMineServiceImpl();
    private webEnterpriseServiceSettlementService = new WebEnterpriseServiceSettlementServiceImpl();
    private webEnterpriseServiceRequestService = new WebEnterpriseServiceRequestServiceImpl();
    private webMessageService = new WebMessageServiceImpl();
    private webEnterpriseServicePackageService = new WebEnterpriseServicePackageServiceImpl();
    private webGeneralReviewService = new WebGeneralReviewServiceImpl();
    private webPersonalService = new WebPersonalServiceImpl();
    private webEnterpriseHomeService = new WebEnterpriseHomeServiceImpl();
    private webEnterpriseServiceOrderService = new WebEnterpriseServiceOrderServiceImpl();
    private webChatService = new WebChatServiceImpl();
    private webSharedInfoService = new WebSharedInfoServiceImpl();

    public getWebEntityService(): WebEntityService {
    	return this.webEntityService;
    }

    public getWebSystemService(): WebSystemService {
    	return this.webSystemService;
    }

    public getWebMineService(): WebMineService {
    	return this.webMineService;
    }

    public getWebEnterpriseServiceSettlementService(): WebEnterpriseServiceSettlementService {
    	return this.webEnterpriseServiceSettlementService;
    }

    public getWebEnterpriseServiceRequestService(): WebEnterpriseServiceRequestService {
    	return this.webEnterpriseServiceRequestService;
    }

    public getWebMessageService(): WebMessageService {
    	return this.webMessageService;
    }

    public getWebEnterpriseServicePackageService(): WebEnterpriseServicePackageService {
    	return this.webEnterpriseServicePackageService;
    }

    public getWebGeneralReviewService(): WebGeneralReviewService {
    	return this.webGeneralReviewService;
    }

    public getWebPersonalService(): WebPersonalService {
    	return this.webPersonalService;
    }

    public getWebEnterpriseHomeService(): WebEnterpriseHomeService {
    	return this.webEnterpriseHomeService;
    }

    public getWebEnterpriseServiceOrderService(): WebEnterpriseServiceOrderService {
    	return this.webEnterpriseServiceOrderService;
    }

    public getWebChatService(): WebChatService {
    	return this.webChatService;
    }

    public getWebSharedInfoService(): WebSharedInfoService {
    	return this.webSharedInfoService;
    }

}
