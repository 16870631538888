import RoleTypeUtils from "@/services/util/RoleTypeUtils";
import {EnterpriseType} from "@/services/datatype/entity/enterprise/EnterpriseType";
import {RoleType} from "@/services/datatype/entity/RoleType";

export default class MenuListUtil {
  static MenuList: string[] = [
    "/layout/home",
    "/layout/order",
    "/layout/active",
    "/layout/entiyInfo",
    "/layout/message",
    "/layout/invoice",
    "/layout/finance",
    "/layout/square",
  ];
  static routingPermissions = {
    lawyer: [0, 3, 4, 6],
    ordinary: [1, 5],
  };
  static newMenuList = [];

  static getWorkRole(enterpriseType: EnterpriseType, roleType: RoleType) {
    MenuListUtil.newMenuList.length = 0;
    return RoleTypeUtils.isLawyer (enterpriseType, roleType)
      ? "lawyer"
      : "ordinary";
  }

  static getMenuList(enterpriseType: EnterpriseType, roleType: RoleType) {
    MenuListUtil.newMenuList.length = 0;
    const currentRole = RoleTypeUtils.isLawyer (enterpriseType, roleType)
      ? "lawyer"
      : "ordinary";
    MenuListUtil.routingPermissions[currentRole].forEach ((value) =>
      MenuListUtil.newMenuList.push ([value, MenuListUtil.MenuList[value]])
    );
    return MenuListUtil.newMenuList;
  }
}
