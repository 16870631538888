import { ElMessage, ElMessageBox } from "element-plus";
import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import LoginView from "../pages/LoginView.vue";
import Layout from "../pages/Layout.vue";
import notFound from "../pages/notFound.vue";
import Talk from "../components/Talk.vue";
import { CheckIslink } from "./route";
import HomeView from "../pages/Layout/HomeView.vue";
import OrderView from "../pages/Layout/OrderView.vue";
// import UserTools from "@/services/session/UserTools";
// import RoleTypeUtils from "@/services/util/RoleTypeUtils";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    meta: {
      title: "企联联登录页",
      isLink: true,
    },
    component: LoginView,
  },
  {
    path: "/:pathMatch(.*)",
    name: "404",
    meta: {
      title: "404",
      isLink: true,
    },
    component: notFound,
  },
  {
    path: "/layout",
    name: "layout",
    meta: {
      title: "企联联",
      isLink: true,
    },
    component: Layout,
    children: [
      {
        path: "/layout/home",
        name: "home",
        meta: {
          title: "企联联首页",
          // isLink: true,
        },
        component: HomeView,
      },
      {
        path: "/layout/order",
        meta: {
          title: "订单管理",
          // isLink: true
        },
        children: [
          {
            path: "/layout/order",
            name: "order",
            meta: {
              title: "订单管理",
              // isLink: true
            },
            component: OrderView,
          },
        ],
      },
      {
        path: "/layout/talk",
        name: "talk",
        meta: {
          title: "聊天室",
          isLink: true,
        },
        component: Talk,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export const addQllRoute = (menu) => {
  menu.forEach((r) => {
    router.addRoute("layout", {
      path: r.path,
      name: r.name,
      meta: {
        title: r.meta.title,
        isLink: r.meta.isLink,
      },
      component: r.component,
      children: r.children ? r.children : [],
    });
  });
};
if (sessionStorage.getItem("linkList")) {
  addQllRoute(
    CheckIslink(JSON.parse(sessionStorage.getItem("linkList") as string))
  );
}
// const userList = UserTools.userRoles.roles;
// console.log(userList);
// 前置守卫
router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    /* 路由发生变化修改页面title */
    // window.location.reload()
    // if (to.path == "/layout") {
    //   RoleTypeUtils.isLawyer(
    //     userList[0].enterpriseInfo.enterpriseType,
    //     userList[0].getRoleType()
    //   )
    //     ? next("/layout/home")
    //     : next("/layout/order");
    // }
    if (to.path == "/") {
      next();
    } else {
      if (sessionStorage.getItem("sessionToken")) {
        if (to.meta.isLink) {
          next();
        } else {
          next(from.path);
          await ElMessageBox.alert("权限不足无法访问", "警告", {
            confirmButtonText: "确定",
          });
        }
      } else {
        next("/");
        if (sessionStorage.getItem("mobile")) {
          await ElMessageBox.alert("请先登录", "提示", {
            confirmButtonText: "确定",
            callback: (): void => {
              ElMessage({
                type: "success",
                message: "已返回登陆页",
              });
            },
          });
        }
      }
    }
  }
);
router.afterEach((to) => {
  //设置跳转路由后页面的标题
  if (to.meta.title) {
    window.document.title = to.meta.title as string;
  }
});
export default router;
