/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { DestinationType } from "./DestinationType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class DestinationConfig extends AbstractSerializable {
  type: DestinationType;
  id: number | string;

  constructor() {
    super();
  }

  public getType(): DestinationType {
    return this.type;
  }

  public setType(type: DestinationType) {
    this.type = type;
  }

  public getId(): number | string {
    return this.id;
  }

  public setId(id: number | string) {
    this.id = id;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const type = json["type"];
    if (type != null) {
      const convertedType0 = DestinationType[<string>type];
      this.setType(convertedType0);
    }
    const id = json["id"];
    if (id != null) {
      this.setId(id);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const type = this.getType();
    if (type != null) {
      const convertedType0 = DestinationType[type];
      json["type"] = convertedType0;
    }
    const id = this.getId();
    if (id != null) {
      json["id"] = String(id);
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.ipsphere.DestinationConfig";
  }
}
