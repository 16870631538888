/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { CoworkIdentifier } from "../cowork/CoworkIdentifier";
import { EntityInfo } from "../entity/EntityInfo";
import {JsonTypeTagType} from "../../serialize/JsonTypeTagType";
import {MessageType} from "../../message/MessageContentType";
import {MessageContent} from "../../message/MessageContent";
import { NotificationType } from "./NotificationType";
import { Serializer } from "../../serialize/Serializer";
import { SubNotificationType } from "./SubNotificationType";
import { TextizableContent } from "../../message/entity/api/TextizableContent";
import { TextType } from "../TextType";
import { TypeFactory } from "../../serialize/TypeFactory";
import { DestinationConfig } from "@/services/datatype/ipsphere/DestinationConfig";

export abstract class AbstractServiceOpNotification
  extends AbstractSerializable
  implements MessageContent, TextizableContent
{
  workId: CoworkIdentifier;
  subCoworkType: string;
  subjectName: string;
  description: string;
  notificationType: NotificationType;
  subNotificationType: SubNotificationType;
  operatorInfo: EntityInfo;
  specialInfo: any;
  destinationConfig: DestinationConfig;

  constructor() {
    super();
  }

  public getWorkId(): CoworkIdentifier {
    return this.workId;
  }

  public setWorkId(workId: CoworkIdentifier) {
    this.workId = workId;
  }

  public getSubCoworkType(): string {
    return this.subCoworkType;
  }

  public setSubCoworkType(subCoworkType: string) {
    this.subCoworkType = subCoworkType;
  }

  public getSubjectName(): string {
    return this.subjectName;
  }

  public setSubjectName(subjectName: string) {
    this.subjectName = subjectName;
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public getNotificationType(): NotificationType {
    return this.notificationType;
  }

  public setNotificationType(notificationType: NotificationType) {
    this.notificationType = notificationType;
  }

  public getSubNotificationType(): SubNotificationType {
    return this.subNotificationType;
  }

  public setSubNotificationType(subNotificationType: SubNotificationType) {
    this.subNotificationType = subNotificationType;
  }

  public getOperatorInfo(): EntityInfo {
    return this.operatorInfo;
  }

  public setOperatorInfo(operatorInfo: EntityInfo) {
    this.operatorInfo = operatorInfo;
  }

  public getSpecialInfo(): any {
    return this.specialInfo;
  }

  public setSpecialInfo(specialInfo: any) {
    this.specialInfo = specialInfo;
  }

    public getDestinationConfig(): DestinationConfig {
        return this.destinationConfig;
    }

    public setDestinationConfig(destinationConfig: DestinationConfig) {
        this.destinationConfig = destinationConfig;
    }

    public getMessageType(): MessageType {
        return MessageType.OP_NOTIFY;
    }

    public getTextType(): TextType {
        return TextType.PLAIN;
    }

    public getText(): string {
        return undefined;
    }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const workId = json["workId"];
    if (workId != null) {
      const convertedWorkId0 = new CoworkIdentifier();
      convertedWorkId0.fillFromJson(workId, factory);
      this.setWorkId(convertedWorkId0);
    }
    const subCoworkType = json["subCoworkType"];
    if (subCoworkType != null) {
      this.setSubCoworkType(subCoworkType);
    }
    const subjectName = json["subjectName"];
    if (subjectName != null) {
      this.setSubjectName(subjectName);
    }
    const description = json["description"];
    if (description != null) {
      this.setDescription(description);
    }
    const notificationType = json["notificationType"];
    if (notificationType != null) {
      const convertedNotificationType0 =
        NotificationType[<string>notificationType];
      this.setNotificationType(convertedNotificationType0);
    }
    const subNotificationType = json["subNotificationType"];
    if (subNotificationType != null) {
      const convertedSubNotificationType0 =
        SubNotificationType[<string>subNotificationType];
      this.setSubNotificationType(convertedSubNotificationType0);
    }
    const operatorInfo = json["operatorInfo"];
    if (operatorInfo != null) {
      const convertedOperatorInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        operatorInfo,
        "__type",
        factory
      );
      this.setOperatorInfo(convertedOperatorInfo0);
    }
    const specialInfo = json["specialInfo"];
    if (specialInfo != null) {
      const convertedSpecialInfo0 = Serializer.fromJson(specialInfo, factory);
      this.setSpecialInfo(convertedSpecialInfo0);
    }
    const destinationConfig = json["destinationConfig"];
    if (destinationConfig != null) {
      const convertedDestinationConfig0 = new DestinationConfig();
      convertedDestinationConfig0.fillFromJson(destinationConfig, factory);
      this.setDestinationConfig(convertedDestinationConfig0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const workId = this.getWorkId();
    if (workId != null) {
      const convertedWorkId0 = workId.getJson(JsonTypeTagType.NONE);
      json["workId"] = convertedWorkId0;
    }
    const subCoworkType = this.getSubCoworkType();
    if (subCoworkType != null) {
      json["subCoworkType"] = subCoworkType;
    }
    const subjectName = this.getSubjectName();
    if (subjectName != null) {
      json["subjectName"] = subjectName;
    }
    const description = this.getDescription();
    if (description != null) {
      json["description"] = description;
    }
    const notificationType = this.getNotificationType();
    if (notificationType != null) {
      const convertedNotificationType0 = NotificationType[notificationType];
      json["notificationType"] = convertedNotificationType0;
    }
    const subNotificationType = this.getSubNotificationType();
    if (subNotificationType != null) {
      const convertedSubNotificationType0 =
        SubNotificationType[subNotificationType];
      json["subNotificationType"] = convertedSubNotificationType0;
    }
    const operatorInfo = this.getOperatorInfo();
    if (operatorInfo != null) {
      const convertedOperatorInfo0 = operatorInfo.getJson(JsonTypeTagType.TYPE);
      json["operatorInfo"] = convertedOperatorInfo0;
    }
    const specialInfo = this.getSpecialInfo();
    if (specialInfo != null) {
      const convertedSpecialInfo0 = Serializer.getJson(
        specialInfo,
        JsonTypeTagType.CLASS
      );
      json["specialInfo"] = convertedSpecialInfo0;
    }
    const destinationConfig = this.getDestinationConfig();
    if (destinationConfig != null) {
      const convertedDestinationConfig0 = destinationConfig.getJson(
        JsonTypeTagType.NONE
      );
      json["destinationConfig"] = convertedDestinationConfig0;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.ipsphere.ServiceOpNotification";
  }

  public getTypeId(): string {
    return "ServiceOpNotification";
  }
}
