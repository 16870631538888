/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../../serialize/AbstractJsonSerializable'
import {EntityInfo} from '../../../entity/EntityInfo'
import {InvoiceStatus} from '../../order/InvoiceStatus'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {PaymentStatus} from '../../payment/PaymentStatus'
import {Serializer} from '../../../../serialize/Serializer'
import {SettlementBillSyncStatus} from './SettlementBillSyncStatus'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export class SettlementBillInfo extends AbstractSerializable {
    billId: (number | string);
    enterpriseInfo: EntityInfo;
    workerInfo: EntityInfo;
    settlementPeriod: number;
    settleAmount: number;
    ajustedAmount: number;
    realAmount: number;
    syncStatus: SettlementBillSyncStatus;
    invoiceStatus: InvoiceStatus;
    paymentStatus: PaymentStatus;
    approverId: (number | string);
    qllAdminId: (number | string);
    userApproveTime: (number | string);
    qllApproveTime: (number | string);
    payTime: (number | string);
    createTime: (number | string);

    constructor() {
        super();
    }

    public getBillId(): (number | string) {
        return this.billId;
    }

    public setBillId(billId: (number | string)) {
        this.billId = billId;
    }

    public getEnterpriseInfo(): EntityInfo {
        return this.enterpriseInfo;
    }

    public setEnterpriseInfo(enterpriseInfo: EntityInfo) {
        this.enterpriseInfo = enterpriseInfo;
    }

    public getWorkerInfo(): EntityInfo {
        return this.workerInfo;
    }

    public setWorkerInfo(workerInfo: EntityInfo) {
        this.workerInfo = workerInfo;
    }

    public getSettlementPeriod(): number {
        return this.settlementPeriod;
    }

    public setSettlementPeriod(settlementPeriod: number) {
        this.settlementPeriod = settlementPeriod;
    }

    public getSettleAmount(): number {
        return this.settleAmount;
    }

    public setSettleAmount(settleAmount: number) {
        this.settleAmount = settleAmount;
    }

    public getAjustedAmount(): number {
        return this.ajustedAmount;
    }

    public setAjustedAmount(ajustedAmount: number) {
        this.ajustedAmount = ajustedAmount;
    }

    public getRealAmount(): number {
        return this.realAmount;
    }

    public setRealAmount(realAmount: number) {
        this.realAmount = realAmount;
    }

    public getSyncStatus(): SettlementBillSyncStatus {
        return this.syncStatus;
    }

    public setSyncStatus(syncStatus: SettlementBillSyncStatus) {
        this.syncStatus = syncStatus;
    }

    public getInvoiceStatus(): InvoiceStatus {
        return this.invoiceStatus;
    }

    public setInvoiceStatus(invoiceStatus: InvoiceStatus) {
        this.invoiceStatus = invoiceStatus;
    }

    public getPaymentStatus(): PaymentStatus {
        return this.paymentStatus;
    }

    public setPaymentStatus(paymentStatus: PaymentStatus) {
        this.paymentStatus = paymentStatus;
    }

    public getApproverId(): (number | string) {
        return this.approverId;
    }

    public setApproverId(approverId: (number | string)) {
        this.approverId = approverId;
    }

    public getQllAdminId(): (number | string) {
        return this.qllAdminId;
    }

    public setQllAdminId(qllAdminId: (number | string)) {
        this.qllAdminId = qllAdminId;
    }

    public getUserApproveTime(): (number | string) {
        return this.userApproveTime;
    }

    public setUserApproveTime(userApproveTime: (number | string)) {
        this.userApproveTime = userApproveTime;
    }

    public getQllApproveTime(): (number | string) {
        return this.qllApproveTime;
    }

    public setQllApproveTime(qllApproveTime: (number | string)) {
        this.qllApproveTime = qllApproveTime;
    }

    public getPayTime(): (number | string) {
        return this.payTime;
    }

    public setPayTime(payTime: (number | string)) {
        this.payTime = payTime;
    }

    public getCreateTime(): (number | string) {
        return this.createTime;
    }

    public setCreateTime(createTime: (number | string)) {
        this.createTime = createTime;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let billId = json["billId"];
        if (billId != null) {
            this.setBillId(billId);
        }
        let enterpriseInfo = json["enterpriseInfo"];
        if (enterpriseInfo != null) {
            let convertedEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(enterpriseInfo, "__type", factory);
            this.setEnterpriseInfo(convertedEnterpriseInfo0);
        }
        let workerInfo = json["workerInfo"];
        if (workerInfo != null) {
            let convertedWorkerInfo0 = Serializer.fillFromJsonObjectWithTypeTag(workerInfo, "__type", factory);
            this.setWorkerInfo(convertedWorkerInfo0);
        }
        let settlementPeriod = json["settlementPeriod"];
        if (settlementPeriod != null) {
            this.setSettlementPeriod(settlementPeriod);
        }
        let settleAmount = json["settleAmount"];
        if (settleAmount != null) {
            this.setSettleAmount(settleAmount);
        }
        let ajustedAmount = json["ajustedAmount"];
        if (ajustedAmount != null) {
            this.setAjustedAmount(ajustedAmount);
        }
        let realAmount = json["realAmount"];
        if (realAmount != null) {
            this.setRealAmount(realAmount);
        }
        let syncStatus = json["syncStatus"];
        if (syncStatus != null) {
            let convertedSyncStatus0 = SettlementBillSyncStatus[<string>syncStatus];
            this.setSyncStatus(convertedSyncStatus0);
        }
        let invoiceStatus = json["invoiceStatus"];
        if (invoiceStatus != null) {
            let convertedInvoiceStatus0 = InvoiceStatus[<string>invoiceStatus];
            this.setInvoiceStatus(convertedInvoiceStatus0);
        }
        let paymentStatus = json["paymentStatus"];
        if (paymentStatus != null) {
            let convertedPaymentStatus0 = PaymentStatus[<string>paymentStatus];
            this.setPaymentStatus(convertedPaymentStatus0);
        }
        let approverId = json["approverId"];
        if (approverId != null) {
            this.setApproverId(approverId);
        }
        let qllAdminId = json["qllAdminId"];
        if (qllAdminId != null) {
            this.setQllAdminId(qllAdminId);
        }
        let userApproveTime = json["userApproveTime"];
        if (userApproveTime != null) {
            this.setUserApproveTime(userApproveTime);
        }
        let qllApproveTime = json["qllApproveTime"];
        if (qllApproveTime != null) {
            this.setQllApproveTime(qllApproveTime);
        }
        let payTime = json["payTime"];
        if (payTime != null) {
            this.setPayTime(payTime);
        }
        let createTime = json["createTime"];
        if (createTime != null) {
            this.setCreateTime(createTime);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let billId = this.getBillId();
        if (billId != null) {
            json["billId"] = String(billId);
        }
        let enterpriseInfo = this.getEnterpriseInfo();
        if (enterpriseInfo != null) {
            let convertedEnterpriseInfo0 = enterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["enterpriseInfo"] = convertedEnterpriseInfo0;
        }
        let workerInfo = this.getWorkerInfo();
        if (workerInfo != null) {
            let convertedWorkerInfo0 = workerInfo.getJson(JsonTypeTagType.TYPE);
            json["workerInfo"] = convertedWorkerInfo0;
        }
        let settlementPeriod = this.getSettlementPeriod();
        if (settlementPeriod != null) {
            json["settlementPeriod"] = settlementPeriod;
        }
        let settleAmount = this.getSettleAmount();
        if (settleAmount != null) {
            json["settleAmount"] = settleAmount;
        }
        let ajustedAmount = this.getAjustedAmount();
        if (ajustedAmount != null) {
            json["ajustedAmount"] = ajustedAmount;
        }
        let realAmount = this.getRealAmount();
        if (realAmount != null) {
            json["realAmount"] = realAmount;
        }
        let syncStatus = this.getSyncStatus();
        if (syncStatus != null) {
            let convertedSyncStatus0 = SettlementBillSyncStatus[syncStatus];
            json["syncStatus"] = convertedSyncStatus0;
        }
        let invoiceStatus = this.getInvoiceStatus();
        if (invoiceStatus != null) {
            let convertedInvoiceStatus0 = InvoiceStatus[invoiceStatus];
            json["invoiceStatus"] = convertedInvoiceStatus0;
        }
        let paymentStatus = this.getPaymentStatus();
        if (paymentStatus != null) {
            let convertedPaymentStatus0 = PaymentStatus[paymentStatus];
            json["paymentStatus"] = convertedPaymentStatus0;
        }
        let approverId = this.getApproverId();
        if (approverId != null) {
            json["approverId"] = String(approverId);
        }
        let qllAdminId = this.getQllAdminId();
        if (qllAdminId != null) {
            json["qllAdminId"] = String(qllAdminId);
        }
        let userApproveTime = this.getUserApproveTime();
        if (userApproveTime != null) {
            json["userApproveTime"] = String(userApproveTime);
        }
        let qllApproveTime = this.getQllApproveTime();
        if (qllApproveTime != null) {
            json["qllApproveTime"] = String(qllApproveTime);
        }
        let payTime = this.getPayTime();
        if (payTime != null) {
            json["payTime"] = String(payTime);
        }
        let createTime = this.getCreateTime();
        if (createTime != null) {
            json["createTime"] = String(createTime);
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.business.service.settlement.SettlementBillInfo";
    }
}
