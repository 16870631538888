/**
 * 上传！
 */
import {
  OSSCredentialProvider,
  OssTokenConsumer,
} from "./OSSCredentialProvider";
import { OSSFederationToken } from "./OSSFederationToken";
import OSS from "ali-oss";
import { nanoid } from "nanoid";
export default class UploadUtils {
  static getFile(
    e: any,
    call:any
  ): any {
    const fileTarget: any = e.target;
    const tokenConsumer: OssTokenConsumer = async (
      token: OSSFederationToken
    ): Promise<any[]> => {
      if (token) {
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: token.tempAK,
          accessKeySecret: token.tempSK,
          stsToken: token.securityToken,
          bucket: "gong-wei",
        });
        let a = []
        for (const files of fileTarget.files) {
          const fullFileName: string =
            token.pkgName + "/" + nanoid(20) + "." + files.name.split(".")[1];
          const result = await client.put(fullFileName, files);
          a.push({...result,fileName:files.name});
        }
        call(a)
      }
    };
    OSSCredentialProvider.getInstance().getFederationToken(tokenConsumer);
  }
}
