import {UserRole} from "@/services/session/UserRole";
import {SystemSuppliedEntityInfo} from "@/services/datatype/entity/SystemSuppliedEntityInfo";
import {AdditionalEntityAttributes} from "@/services/datatype/entity/AdditionalEntityAttributes";
import {UserDetails} from "@/services/datatype/entity/user/UserDetails";
import {Profession} from "@/services/datatype/entity/user/Profession";
import {AssociatedEntityInfo} from "@/services/datatype/entity/AssociatedEntityInfo";
import {EntityType} from "@/services/datatype/entity/EntityType";
import {RoleType} from "@/services/datatype/entity/RoleType";

export class UserRoles {
  private _userId: number | string;
  private _roles: Array<UserRole>;
  profession: Profession;
  private _entityInfo: SystemSuppliedEntityInfo;

  constructor() {
  }

  get entityInfo(): SystemSuppliedEntityInfo {
    return this._entityInfo;
  }

  set entityInfo(entityInfo: SystemSuppliedEntityInfo) {
    this._entityInfo = entityInfo;
    if (entityInfo) {
      this._userId = entityInfo.entityDesc.nid;
      const attributes: AdditionalEntityAttributes =
        entityInfo.entityDesc.getAdditionalAttributes();
      const roles: Array<UserRole> = [];
      let mainRole: UserRole = null;
      if (attributes instanceof UserDetails) {
        const userDetails: UserDetails = attributes as UserDetails;
        this.profession = userDetails.profession;
        let employerId: number | string | null = null;
        if (userDetails.workerInfo?.enterpriseInfo?.entityId) {
          console.log (userDetails)
          mainRole = new UserRole();
          employerId = userDetails.workerInfo.enterpriseInfo.entityId;
          mainRole.enterpriseInfo = userDetails.workerInfo.enterpriseInfo;
          mainRole.position = userDetails.workerInfo.position;
          mainRole.userWorkPage = this.profession == Profession.LAWYER;
          roles.push(mainRole);
        }
        const adminInfo = userDetails.userEntityAdminInfo;
        if (adminInfo && adminInfo.roledEntityInfos) {
          let role: UserRole;
          let associatedEntityInfo: AssociatedEntityInfo;
          for (let i = 0; i < adminInfo.roledEntityInfos.length; i++) {
            associatedEntityInfo = adminInfo.roledEntityInfos[i];
            if (
              associatedEntityInfo.entityInfo &&
              associatedEntityInfo.entityInfo.entityType ==
                EntityType.ENTERPRISE
            ) {
              if (mainRole && (associatedEntityInfo.entityInfo.entityId == employerId) && !mainRole.roleType) {
                mainRole.roleType = associatedEntityInfo.roleType;
              } else {
                role = new UserRole();
                role.roleType = associatedEntityInfo.roleType;
                role.enterpriseInfo = associatedEntityInfo.entityInfo;
                roles.push(role);
              }
            }
          }
        }
        const logoutRole = new UserRole();
        logoutRole.roleType = RoleType.VISITOR;
        roles.push(logoutRole);

        this._roles = roles;
      }
    }
  }

  get roles(): Array<UserRole> {
    return this._roles;
  }

  get userId(): number | string {
    return this._userId;
  }

  public getFirstRole(): UserRole | null {
    if (this._roles && this._roles.length > 0) {
      return this._roles[0];
    } else {
      return null;
    }
  }
}
