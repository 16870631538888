import {RequestMessage} from "@/services/message/entity/request/RequestMessage";
import {RequestStatusMessage} from "@/services/message/entity/request/RequestStatusMessage";
import {ServiceOpMessage} from "@/services/message/business/service/ServiceOpMessage";
import UserTools from "@/services/session/UserTools";
import {DefaultWebServiceFactory} from "@/services/webapi/DefaultWebServiceFactory";
import {RequestStatusTypeName} from "@/services/datatype/request/RequestStatusTypeName";
import order from "@/api/order";
import Message from "@/api/message";

export default class MessageUtil {
  static getMessage(messageList, callback?) {
    messageList && messageList.splice(0, messageList.length);
    DefaultWebServiceFactory.getInstance()
      .getWebMessageService()
      .addMessageListenerForRequestOrStatus(async (message) => {
        if (!message) return;
        // 新消息
        if (
          message.filter((value) => value).length <= 199 &&
          message.length < 200
        ) {
          MessageUtil.chooseNewMessage(message, messageList, callback);
        } else {
          // console.log(message);
          message.forEach((value) => {
            MessageUtil.chooseOldMessage(value, messageList);
          });
        }
      });
  }
  static chooseNewMessage(message, messageList, callback?) {
    console.log (message, 8989889);
    if (
      message.filter ((value) => value)[0]["message"] instanceof
      RequestMessage &&
      message.filter ((value) => value)[0]["message"].data.requestId
    ) {
      console.log (152555222555);
      DefaultWebServiceFactory.getInstance ()
        .getWebEnterpriseServiceRequestService ()
        .getEnterpriseServiceRequestById (
          message.filter ((value) => value)[0]["message"].data.requestId
        )
        .then ((t) => {
          if (t.servingProfessionalInfo.entityId == UserTools.getUserId ()) {
            callback &&
            Notification.permission == "granted" &&
            callback (
                `您有新的${t.trade.title}服务请求！`,
                message.filter((value) => value)[0]["message"].id,
                message.filter((value) => value)[0]["message"].data.requestId
              );
          }
        });
    } else if (
      message.filter((value) => value)[0] instanceof ServiceOpMessage &&
      message.filter((value) => value)[0].data.subNotificationType
    ) {
      callback &&
        Notification.permission == "granted" &&
        callback(
          message.filter((value) => value)[0]["message"].data.description,
          message.filter((value) => value)[0]["message"].id,
          message.filter((value) => value)[0]["message"].data.workId.id
        );
      messageList &&
        messageList.unshift({
          orderId: message.filter ((value) => value)[0]["message"].id,
          content: message.filter ((value) => value)[0]["message"].data
            .description,
          time: message.filter ((value) => value)[0]["message"].createTime,
          requestId: message.filter ((value) => value)[0]["message"].data.workId
            .id,
          isRead: Boolean (message.filter ((value) => value)[0]["read"]),
          status: 1,
        });
    } else if (
      message.filter((value) => value)[0]["message"] instanceof
        RequestStatusMessage &&
      message.filter((value) => value)[0]["message"].data.status
    ) {
      // console.log (666666)
      // callback &&
      //   Notification.permission == "granted" &&
      //   callback(
      //     `您的订单 ${
      //       message.filter((value) => value)[0]["message"].data.requestId
      //     }, ${RequestStatusTypeName.getDisplayName(
      //       message.filter((value) => value)[0]["message"].data.status
      //     )}`,
      //     message.filter((value) => value)[0]["message"].id,
      //     message.filter((value) => value)[0]["message"].data.requestId
      //   );
    } else if (message.filter((value) => value)[0]) {
      if (message.length > 20) {
        callback &&
          callback(
          null,
          null,
          message.filter ((value) => value)[0]["message"].serviceRequestId
        );
      } else {
        callback &&
        callback (
          "你有一条新的消息",
          null,
          message.filter ((value) => value)[0]["message"].serviceRequestId
        );
      }
    }
  }
  static chooseOldMessage(value, messageList) {
    if (!value["read"] && !(value instanceof RequestMessage)) {
      if (value["message"] instanceof ServiceOpMessage) {
        console.log (value["message"], 777);
        value["message"] &&
        messageList &&
        messageList.push ({
          orderId: value["message"].id,
          content: value["message"].data.description,
          time: value["message"].createTime,
          requestId: value["message"].data.workId.id,
          isRead: Boolean (value["read"]),
          status: 1,
        });
        messageList &&
        messageList.forEach ((r) => {
          order.getOrder (r.requestId).then ((t) => {
            if (t.status == 12) {
              Message.setRead (r.orderId);
            }
          });
        });
      } else if (value["message"] instanceof RequestStatusMessage) {
        console.log (value);
        messageList &&
        messageList.push ({
          orderId: value["message"].id,
          content: `您的订单 ${
            value["message"].data.requestId
          }, ${RequestStatusTypeName.getDisplayName (
            value["message"].data.status
          )}，请进入服务对话`,
          time: value["message"].createTime,
            requestId: value["message"].data.requestId,
            isRead: Boolean(value["read"]),
            status: 2,
          });
      }
    }
  }
}
