/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../common/AbstractClientServiceAdapter'
import {AbstractEnterpriseServiceTrade} from '../datatype/business/service/trade/AbstractEnterpriseServiceTrade'
import {DefaultServiceFactory} from '../api/DefaultServiceFactory'
import {DefaultWebServiceFactory} from '../webapi/DefaultWebServiceFactory'
import {EnterpriseServiceOrderService} from '../api/business/service/EnterpriseServiceOrderService'
import {EnterpriseServiceOrder} from '../datatype/business/order/EnterpriseServiceOrder'
import {EnterpriseServicePackageTrade} from '../datatype/business/service/trade/EnterpriseServicePackageTrade'
import {EnterpriseServiceTradeInfo} from '../datatype/business/service/trade/EnterpriseServiceTradeInfo'
import {InvoiceRequest} from '../datatype/business/order/InvoiceRequest'
import {OrderQuerySpec} from '../datatype/business/order/OrderQuerySpec'
import {OrderStatus} from '../datatype/business/order/OrderStatus'
import {PageableData} from '../datatype/paging/PageableData'
import {PaymentSpec} from '../datatype/business/payment/PaymentSpec'
import {ThirdPartyPayClientInfo} from '../datatype/business/payment/ThirdPartyPayClientInfo'
import {WebEnterpriseServiceOrderService} from '../webapi/WebEnterpriseServiceOrderService'
import {WebSessionManager} from '../common/WebSessionManager'


export class WebEnterpriseServiceOrderServiceImpl implements WebEnterpriseServiceOrderService {

	private enterpriseServiceOrderService: EnterpriseServiceOrderService;

	public constructor() {
		this.enterpriseServiceOrderService = DefaultServiceFactory.getInstance().getEnterpriseServiceOrderService();
	}

	public addEnterpriseServiceOrder(enterpriseServiceOrder: EnterpriseServiceOrder): Promise<AbstractEnterpriseServiceTrade> {
		return new Promise((resolve, reject) => {
			this.enterpriseServiceOrderService.addEnterpriseServiceOrder(WebSessionManager.getSessionContext(),
				enterpriseServiceOrder,
				(t, u) => {
					if (u) {
						reject(u);
					} else {
						resolve(t);
					}
				});
		});
	}

	public attachEnterpirseToTrade(tradeId: (number | string | null), enterpriseId: (number | string | null)): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.enterpriseServiceOrderService.attachEnterpirseToTrade(WebSessionManager.getSessionContext(),
				tradeId, enterpriseId,
				(t, u) => {
					if (u) {
						reject(u);
					} else {
						resolve(t);
					}
				});
		});
	}

	public cancelEnterpriseServiceOrder(enterpriseServiceOrderId: (number | string | null), reason: (string | null)): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.enterpriseServiceOrderService.cancelEnterpriseServiceOrder(WebSessionManager.getSessionContext(),
				enterpriseServiceOrderId, reason,
				(t, u) => {
					if (u) {
						reject(u);
					} else {
						resolve(t);
					}
				});
		});
	}

	public findEnterpriseServiceOrdersByRegionId(regionId: (number | string | null), orderQuerySpec: (OrderQuerySpec | null)): Promise<PageableData<EnterpriseServiceOrder>> {
		return new Promise((resolve, reject) => {
			this.enterpriseServiceOrderService.findEnterpriseServiceOrdersByRegionId(WebSessionManager.getSessionContext(),
				regionId, orderQuerySpec,
				(t, u) => {
					if (u) {
						reject(u);
					} else {
						resolve(t);
					}
				});
		});
	}

	public findEnterpriseServicePackageTradeByOrderId(enterpriseServiceOrderId: (number | string | null)): Promise<EnterpriseServicePackageTrade> {
		return new Promise((resolve, reject) => {
			this.enterpriseServiceOrderService.findEnterpriseServicePackageTradeByOrderId(WebSessionManager.getSessionContext(),
				enterpriseServiceOrderId,
				(t, u) => {
					if (u) {
						reject(u);
					} else {
						resolve(t);
					}
				});
		});
	}

	public findEnterpriseServiceTradeById(tradeId: (number | string | null)): Promise<AbstractEnterpriseServiceTrade> {
		return new Promise((resolve, reject) => {
			this.enterpriseServiceOrderService.findEnterpriseServiceTradeById(WebSessionManager.getSessionContext(),
				tradeId,
				(t, u) => {
					if (u) {
						reject(u);
					} else {
						resolve(t);
					}
				});
		});
	}

	public findEnterpriseServiceTradesByOrderId(enterpriseServiceOrderId: (number | string | null)): Promise<Array<EnterpriseServiceTradeInfo>> {
		return new Promise((resolve, reject) => {
			this.enterpriseServiceOrderService.findEnterpriseServiceTradesByOrderId(WebSessionManager.getSessionContext(),
				enterpriseServiceOrderId,
				(t, u) => {
					if (u) {
						reject(u);
					} else {
						resolve(t);
					}
				});
		});
	}

	public findMainEnterpriseServiceTradeByOrderId(enterpriseServiceOrderId: (number | string | null)): Promise<AbstractEnterpriseServiceTrade> {
		return new Promise((resolve, reject) => {
			this.enterpriseServiceOrderService.findMainEnterpriseServiceTradeByOrderId(WebSessionManager.getSessionContext(),
				enterpriseServiceOrderId,
				(t, u) => {
					if (u) {
						reject(u);
					} else {
						resolve(t);
					}
				});
		});
	}

	public getEnterpriseServiceOrderByOrderId(enterpriseServiceOrderId: (number | string | null)): Promise<EnterpriseServiceOrder> {
		return new Promise((resolve, reject) => {
			this.enterpriseServiceOrderService.getEnterpriseServiceOrderByOrderId(WebSessionManager.getSessionContext(),
				enterpriseServiceOrderId,
				(t, u) => {
					if (u) {
						reject(u);
					} else {
						resolve(t);
					}
				});
		});
	}

	public getEnterpriseServiceOrderByOrderIdAfterPaySuccess(enterpriseServiceOrderId: (number | string | null)): Promise<EnterpriseServiceOrder> {
		return new Promise((resolve, reject) => {
			this.enterpriseServiceOrderService.getEnterpriseServiceOrderByOrderIdAfterPaySuccess(WebSessionManager.getSessionContext(),
				enterpriseServiceOrderId,
				(t, u) => {
					if (u) {
						reject(u);
					} else {
						resolve(t);
					}
				});
		});
	}

	public getEnterpriseServiceOrderStatusByOrderId(enterpriseServiceOrderId: (number | string | null)): Promise<OrderStatus> {
		return new Promise((resolve, reject) => {
			this.enterpriseServiceOrderService.getEnterpriseServiceOrderStatusByOrderId(WebSessionManager.getSessionContext(),
				enterpriseServiceOrderId,
				(t, u) => {
					if (u) {
						reject(u);
					} else {
						resolve(t);
					}
				});
		});
	}

	public getPayClientInfoForServiceOrder(payment: PaymentSpec): Promise<ThirdPartyPayClientInfo> {
		return new Promise((resolve, reject) => {
			this.enterpriseServiceOrderService.getPayClientInfoForServiceOrder(WebSessionManager.getSessionContext(),
				payment,
				(t, u) => {
					if (u) {
						reject(u);
					} else {
						resolve(t);
					}
				});
		});
	}

	public renewServiceOrder(currentOrderId: (number | string | null)): Promise<AbstractEnterpriseServiceTrade> {
		return new Promise((resolve, reject) => {
			this.enterpriseServiceOrderService.renewServiceOrder(WebSessionManager.getSessionContext(),
				currentOrderId,
				(t, u) => {
					if (u) {
						reject(u);
					} else {
						resolve(t);
					}
				});
		});
	}

	public renewServiceOrderByCurrentTradeId(currentTradeId: (number | string | null)): Promise<AbstractEnterpriseServiceTrade> {
		return new Promise((resolve, reject) => {
			this.enterpriseServiceOrderService.renewServiceOrderByCurrentTradeId(WebSessionManager.getSessionContext(),
				currentTradeId,
				(t, u) => {
					if (u) {
						reject(u);
					} else {
						resolve(t);
					}
				});
		});
	}

	public submitInvoiceRequest(invoiceRequest: (InvoiceRequest | null)): Promise<(number | string)> {
		return new Promise((resolve, reject) => {
			this.enterpriseServiceOrderService.submitInvoiceRequest(WebSessionManager.getSessionContext(),
				invoiceRequest,
				(t, u) => {
					if (u) {
						reject(u);
					} else {
						resolve(t);
					}
				});
		});
	}

}
