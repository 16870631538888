/**
 * 复制！
 */
import { ElMessage } from "element-plus";

export default class CopyUtils {
  static async copy(id) {
    await navigator.clipboard.writeText(id);
    ElMessage({
      type: "success",
      message: "复制成功",
    });
  }
}
