/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { Filter } from "../../query/Filter";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { OrderStatus } from "./OrderStatus";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class OrderFilter extends AbstractSerializable implements Filter {
  status: OrderStatus;

  constructor() {
    super();
  }

  public getStatus(): OrderStatus {
    return this.status;
  }

  public setStatus(status: OrderStatus) {
    this.status = status;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const status = json["status"];
    if (status != null) {
      const convertedStatus0 = OrderStatus[<string>status];
      this.setStatus(convertedStatus0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const status = this.getStatus();
    if (status != null) {
      const convertedStatus0 = OrderStatus[status];
      json["status"] = convertedStatus0;
    }

    if (includeTypeTag) {
      json["__type"] = "OrderFilter";
    }
  }

  public getTypeId(): string {
    return "OrderFilter";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.order.OrderFilter";
  }
}
