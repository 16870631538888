/**
 * 自动生成， 请不要手工修改！
 */

import {BiConsumer} from "../util/BiConsumer";
import {DefaultServiceFactory} from "../api/DefaultServiceFactory";
import {EntityMessageResponse} from "../message/entity/api/EntityMessageResponse";
import {EntityMessage} from "../message/entity/api/EntityMessage";
import {LocalChat} from "../datatype/geolocation/LocalChat";
import {Location} from "../datatype/geolocation/Location";
import {MessageQuerySpec} from "../datatype/query/message/MessageQuerySpec";
import {MessageService} from "../api/message/MessageService";
import {MultiMessageResponse} from "../datatype/message/MultiMessageResponse";
import {PageableData} from "../datatype/paging/PageableData";
import {PageBlockAccessSpec} from "../datatype/paging/PageBlockAccessSpec";
import {Pair} from "../util/Pair";
import {PlatformDataContainer} from "../datatype/platform/PlatformDataContainer";
import {ServiceResponseHandle} from "../api/datatype/ServiceResponseHandle";
import {SyncResponse} from "../datatype/platform/SyncResponse";
import {MessageFilter, WebMessageService} from "../webapi/WebMessageService";
import {WebSessionManager} from "../common/WebSessionManager";
import EventHandle from "@/services/common/EventHandle";
import {EntityChannelType} from "@/services/datatype/entity/channel/EntityChannelType";
import {MessageHandle} from "@/services/webapi/MessageHandle";
import {MessageListenerEntry} from "@/services/webimpl/MessageListenerEntry";
import {DefaultMessageHandle} from "@/services/webimpl/DefaultMessageHandle";
import {ServiceError} from "@/services/common";
import {Consumer} from "@/services/util";
import {WebSocketConnection} from "@/services/connection";
import {ConnectionManagerFactory} from "@/services/connection/ConnectionManagerFactory";
import {TextMessage} from "@/services/message/entity/TextMessage";
import UserTools from "@/services/session/UserTools";
import {URLMessage} from "@/services/message/entity/URLMessage";
import {ServiceRequestOpMessage} from "@/services/message/business/service/ServiceRequestOpMessage";
import {ServiceRequestOp} from "@/services/message/business/service/ServiceRequestOp";
import {CoworkOp} from "@/services/datatype/cowork/CoworkOp";
import {LinkType} from "@/services/message/entity/api/LinkType";
import {RequestMessage} from "@/services/message/entity/request/RequestMessage";
import {RequestStatusMessage} from "@/services/message/entity/request/RequestStatusMessage";
import {RequestStatusTypeUtils} from "@/services/datatype/request/RequestStatusTypeUtils";
import {InboxMessageWrapper} from "@/services/message/entity/api/InboxMessageWrapper";
import {ServiceOpMessage} from "@/services/message/business/service/ServiceOpMessage";

export class WebMessageServiceImpl implements WebMessageService {
  private static readonly SUBSCRIBE_INTERVAL: number = 10000;

  private messageService: MessageService;
  private messageListeners: Array<MessageListenerEntry> = [];
  private eventHandle: EventHandle;
  private msgSubscribeTime: number;
  private lastSyncPoint = 0;
  private receivedMessages: Array<EntityMessage> = [];

  public constructor() {
    this.messageService =
      DefaultServiceFactory.getInstance().getMessageService();
    ConnectionManagerFactory.getOrCreateWebSocketConnectionManager().addConnectionCreateListener(
      (connection: WebSocketConnection) => {
        this.onNewConnection(connection);
      }
    );
  }

  addMessageListener(
    messageFilter: MessageFilter,
    messageListener: BiConsumer<Array<EntityMessage>, any>
  ): MessageHandle {
    const listenerEntry = new MessageListenerEntry(
      messageFilter,
      messageListener
    );
    this.messageListeners.push(listenerEntry);
    if (this.receivedMessages.length > 0) {
      listenerEntry.onMessages(this.receivedMessages);
    }
    return new DefaultMessageHandle(listenerEntry, this.messageListeners);
  }

  addMessageListenerOnChannel(
    channelType: EntityChannelType,
    messageListener: BiConsumer<Array<EntityMessage>, any>
  ): MessageHandle {
    const messageFilter: MessageFilter = (entityMessage: EntityMessage) =>
      entityMessage && entityMessage.getMediumChannelId() == channelType;
    return this.addMessageListener(messageFilter, messageListener);
  }

  addMessageListenerOnChannelWithFilter(
    channelType: EntityChannelType,
    filter: MessageFilter,
    messageListener: BiConsumer<Array<EntityMessage>, any>
  ): MessageHandle {
    const composedMessageFilter: MessageFilter = (
      entityMessage: EntityMessage
    ) =>
      entityMessage &&
      entityMessage.getMediumChannelId() == channelType &&
      (!filter || filter(entityMessage));
    return this.addMessageListener(composedMessageFilter, messageListener);
  }

  addMessageListenerOnServiceRequest(
    serviceRequestId: number | string,
    messageListener: BiConsumer<Array<EntityMessage>, any>
  ): MessageHandle {
    this.receiveMessages(null);
    const messageFilter: MessageFilter = (entityMessage: EntityMessage) => {
      console.log (entityMessage,99999999);
      return (
        entityMessage && entityMessage["message"].getServiceRequestId() == serviceRequestId
      );
    };
    return this.addMessageListener(messageFilter, messageListener);
  }

  addMessageListenerForRequestOrStatus(
    messageListener: BiConsumer<Array<EntityMessage>, any>
  ): MessageHandle {
    const messageFilter: MessageFilter = (entityMessage: EntityMessage) => {
      if (!entityMessage) {
        return false;
      }
      let coreMessage;
      if (entityMessage instanceof InboxMessageWrapper) {
        const inboxMessage = entityMessage as InboxMessageWrapper;
        coreMessage = inboxMessage.message;
      } else {
        coreMessage = entityMessage;
      }
      if (coreMessage instanceof RequestMessage) {
        return true;
      } else if (coreMessage instanceof RequestStatusMessage) {
        const statusMessage = coreMessage as RequestStatusMessage;
        const status = statusMessage.getData();
        if (status) {
          const statusType = status.getStatus();
          const coworkType = status.getTargetType();
          return RequestStatusTypeUtils.isStatusProcessedOrCompleted(
              statusType,
              coworkType
          );
        } else {
          return false;
        }
      }
      if (coreMessage instanceof ServiceRequestOpMessage || coreMessage instanceof TextMessage) {
        return true;
      } else if (coreMessage instanceof ServiceOpMessage) {
        let serviceOpMsg: ServiceOpMessage = coreMessage as ServiceOpMessage;
        return serviceOpMsg.isForServiceRequest();
      } else {
        return false;
      }
    };
    return this.addMessageListener(messageFilter, messageListener);
  }

  // public msgSubscribe(
  //   message: string | null
  // ): Promise<ServiceResponseHandle<string>> {
  //   return new Promise((resolve, reject) => {
  //     // this.messageService.msgSubscribe(WebSessionManager.getSessionContext(),
  //     // message,
  //     // (t) => {
  //     // 	resolve(t);
  //     // });
  //   });
  // }

  // public subscribeLocalChats(
  //   location: Location | null,
  //   radius: number | null
  // ): Promise<ServiceResponseHandle<MultiMessageResponse>> {
  //   return new Promise((resolve, reject) => {
  //     // this.messageService.subscribeLocalChats(WebSessionManager.getSessionContext(),
  //     // location, radius,
  //     // (t) => {
  //     // 	resolve(t);
  //     // });
  //   });
  // }

  // public subscribeMessages(
  //   location: Location | null,
  //   radius: number | null,
  //   pageBlockSpec: PageBlockAccessSpec | null,
  //   lastSyncPoint: number | string | null
  // ): Promise<ServiceResponseHandle<MultiMessageResponse>> {
  //   return new Promise((resolve, reject) => {
  //     // this.messageService.subscribeMessages(WebSessionManager.getSessionContext(),
  //     // location, radius, pageBlockSpec, lastSyncPoint,
  //     // (t) => {
  //     // 	resolve(t);
  //     // });
  //   });
  // }

  // public subscribePrivateMessages(
  //   lastSyncPoint: number | string | null
  // ): Promise<ServiceResponseHandle<MultiMessageResponse>> {
  //   return new Promise((resolve, reject) => {
  //     // this.messageService.subscribePrivateMessages(WebSessionManager.getSessionContext(),
  //     // lastSyncPoint,
  //     // (t) => {
  //     // 	resolve(t);
  //     // });
  //   });
  // }

  public addLocalChat(
    localChat: LocalChat | null,
    location: Location | null
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.messageService.addLocalChat(
        WebSessionManager.getSessionContext(),
        localChat,
        location,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public changeSyncLocation(
    requestId: string | null,
    location: Location | null,
    radius: number | null,
    pageBlockSpec: PageBlockAccessSpec | null
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.messageService.changeSyncLocation(
        WebSessionManager.getSessionContext(),
        requestId,
        location,
        radius,
        pageBlockSpec,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public deleteMessages(
    messageIds: Array<number | string> | null
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      this.messageService.deleteMessages(
        WebSessionManager.getSessionContext(),
        messageIds,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public getDescendantMessages(
    rootMessageId: number | string | null,
    querySpec: MessageQuerySpec | null
  ): Promise<PageableData<EntityMessage>> {
    return new Promise((resolve, reject) => {
      this.messageService.getDescendantMessages(
        WebSessionManager.getSessionContext(),
        rootMessageId,
        querySpec,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public getPrivateChatMessages(
    rootMessageId: number | string | null,
    conversationParty1: number | string | null,
    conversationParty2: number | string | null,
    querySpec: MessageQuerySpec | null
  ): Promise<PageableData<EntityMessage>> {
    return new Promise((resolve, reject) => {
      this.messageService.getPrivateChatMessages(
        WebSessionManager.getSessionContext(),
        rootMessageId,
        conversationParty1,
        conversationParty2,
        querySpec,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public sendMessage(
    message: EntityMessage | null
  ): Promise<PlatformDataContainer<EntityMessageResponse>> {
    return new Promise((resolve, reject) => {
      this.messageService.sendMessage(
        WebSessionManager.getSessionContext(),
        message,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public sendMessages(
    messages: Array<EntityMessage> | null
  ): Promise<
    PlatformDataContainer<
      Pair<EntityMessageResponse[], Array<Pair<number, string>>>
    >
  > {
    this.checkToResubscribe();
    return new Promise((resolve, reject) => {
      this.messageService.sendMessages(
        WebSessionManager.getSessionContext(),
        messages,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public sendPrivateChatMessage(
    message: EntityMessage | null
  ): Promise<PlatformDataContainer<EntityMessageResponse>> {
    this.checkToResubscribe();
    return new Promise((resolve, reject) => {
      this.messageService.sendPrivateChatMessage(
        WebSessionManager.getSessionContext(),
        message,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public sendTextMessageOnServiceRequest(
    targetId: number | string,
    correationId: number | string | null,
    serviceRequestId: number | string | null,
    message: string
  ): Promise<PlatformDataContainer<EntityMessageResponse>> {
    const msg: TextMessage = new TextMessage();
    msg.setCreateTime(Date.now());
    msg.setCorrelationId(correationId);
    msg.setServiceRequestId(serviceRequestId);
    msg.setSenderEntityInfo(UserTools.entityInfo);
    msg.setMediumEntityId(targetId);
    msg.setMediumChannelId(EntityChannelType.PRIVATE_CHAT);
    msg.setServiceRequestId(serviceRequestId);

    msg.setData(message);

    return this.sendPrivateChatMessage(msg);
  }

  public sendFileMessageOnServiceRequest(
    targetId: number | string,
    correationId: number | string | null,
    serviceRequestId: number | string | null,
    fileName: string | null,
    fileUrl: string | null,
    fileSize: number | null,
    linkType: LinkType
  ): Promise<PlatformDataContainer<EntityMessageResponse>> {
    const msg: URLMessage = new URLMessage();
    msg.setCreateTime(Date.now());
    msg.setCorrelationId(correationId);
    msg.setServiceRequestId(serviceRequestId);
    msg.setSenderEntityInfo(UserTools.entityInfo);
    msg.setMediumEntityId(targetId);
    msg.setMediumChannelId(EntityChannelType.PRIVATE_CHAT);
    msg.setServiceRequestId(serviceRequestId);

    msg.fileName = fileName;
    msg.fileSize = fileSize;
    msg.link = fileUrl;
    msg.linkType = linkType;

    return this.sendPrivateChatMessage(msg);
  }

  public sendServiceRequestOpMessageOnServiceRequest(
    targetId: number | string,
    correationId: number | string | null,
    serviceRequestId: number | string | null,
    coworkOp: CoworkOp
  ): Promise<PlatformDataContainer<EntityMessageResponse>> {
    const msg = new ServiceRequestOpMessage();
    msg.setCreateTime(Date.now());
    msg.setCorrelationId(correationId);
    msg.setServiceRequestId(serviceRequestId);
    msg.setSenderEntityInfo(UserTools.entityInfo);
    msg.setMediumEntityId(targetId);
    msg.setMediumChannelId(EntityChannelType.PRIVATE_CHAT);
    msg.setServiceRequestId(serviceRequestId);

    const op = new ServiceRequestOp();
    op.coworkOp = coworkOp;

    msg.setData(op);

    return this.sendPrivateChatMessage(msg);
  }

  public setReadMessage(messageId: number | string | null): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.messageService.setReadMessage(
        WebSessionManager.getSessionContext(),
        messageId,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public setReadMessages(
    messageIds: Array<number | string> | null
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      this.messageService.setReadMessages(
        WebSessionManager.getSessionContext(),
        messageIds,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  // public subscribeLocalChats(location: (Location | null), radius: (number | null),
  //                            eventHandler: (BiConsumer<EventHandle, any> | null))
  //     : Promise<ServiceResponseHandle<MultiMessageResponse>> {
  //     return new Promise((resolve, reject) => {
  //         this.messageService.subscribeLocalChats(WebSessionManager.getSessionContext(),
  //             location, radius, eventHandler,
  //             (t, u) => {
  //                 if (u) {
  //                     reject(u);
  //                 } else {
  //                     resolve(t);
  //                 }
  //             });
  //     });
  // }

  public subscribePrivateMessages(
    lastSyncPoint: number | string | null,
    eventHandler: BiConsumer<EventHandle, any> | null
  ): Promise<ServiceResponseHandle<MultiMessageResponse>> {
    return new Promise((resolve, reject) => {
      this.messageService.subscribePrivateMessagesWithEventHandler(
        WebSessionManager.getSessionContext(),
        lastSyncPoint,
        eventHandler,
        (t) => {
          resolve(t);
        }
      );
    });
  }

  public sync(lastSyncPoint: number | string | null): Promise<SyncResponse> {
    return new Promise((resolve, reject) => {
      this.messageService.sync(
        WebSessionManager.getSessionContext(),
        lastSyncPoint,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  private checkMsgSubscribeStatus(): boolean {
    const currentTime = Date.now();
    const disStamp = currentTime - this.msgSubscribeTime;
    if (!disStamp || disStamp > WebMessageServiceImpl.SUBSCRIBE_INTERVAL) {
      this.msgSubscribeTime = currentTime;
      return true;
    } else {
      return false;
    }
  }

  private notifyMessages(receivedMessages: Array<EntityMessage>) {
    if (receivedMessages && receivedMessages.length > 0) {
      if (this.messageListeners && this.messageListeners.length > 0) {
        for (const listenerEntry of this.messageListeners) {
          try {
            listenerEntry.onMessages(receivedMessages);
          } catch (error: any) {}
        }
      }
    }
  }

  private handleSyncResponse(syncResponse: SyncResponse) {
    if (syncResponse) {
      this.lastSyncPoint = syncResponse.nextSyncPosition;
      const receivedMessages = syncResponse.messages;
      if (receivedMessages) {
        for (const msg of receivedMessages) {
          this.receivedMessages.push(msg);
        }
      }
      // 通知
      this.notifyMessages(receivedMessages);
      // 缓存
      //         cacheMessages(receivedMessages);
      // 更新同步定位
      //         setLastSyncPosition(syncResponse.getNextSyncPosition());
    }
  }

  private receiveMessages(location: Location) {
    if (this.checkMsgSubscribeStatus()) {
      const lastSyncPoint = this.lastSyncPoint;
      //Log.d("AppPushMessageService", "Get LastSyncPosition：" + position);
      const eventHandler: BiConsumer<EventHandle, any> = (
        eventHandle: EventHandle,
        error: any
      ) => {
        if (eventHandle) {
          this.eventHandle = eventHandle;
        } else if (error && ServiceError.checkDisconnectedErrorForAny(error)) {
          if (this.eventHandle) {
            // 似乎不需要特别做什么
            // 这一步多余
            this.eventHandle.setCancelled();
          }
        }
      };
      const resultHandler: Consumer<
        ServiceResponseHandle<MultiMessageResponse>
      > = (
        multiResponseHandle: ServiceResponseHandle<MultiMessageResponse>
      ) => {
        // 私信
        if (!multiResponseHandle.getError()) {
          let actualResult: any = multiResponseHandle.getResult();
          const responseHandle = actualResult as ServiceResponseHandle<any>;
          if (responseHandle) {
            actualResult = responseHandle.getResult();
          }
          if (actualResult instanceof MultiMessageResponse) {
            const response: MultiMessageResponse =
              actualResult as MultiMessageResponse;
            if (response) {
              const result = response.getMessage();
              if (result) {
                // 更新lastpoint
                //this.lastSyncPoint = result.nextSyncPosition;
                //                        syncMonitor.clearSyncInprogress(lastSyncPosition);
                //Log.d("AppPushMessageService", "Save LastSyncPosition：" + lastSyncPosition);
                //[[UserTools sharedUserTools] setMessageLastSyncPosition: lastSyncPosition];
                this.handleSyncResponse(result);
              }
            }
          }
        }
      };
      this.messageService.subscribePrivateMessagesWithEventHandler(
        WebSessionManager.getSessionContext(),
        lastSyncPoint,
        eventHandler,
        resultHandler
      );
    }
  }

  public stop(): void {
    if (this.eventHandle) {
      this.eventHandle.cancel();
    }
  }

  public checkToResubscribe(): void {
    if (UserTools.isLogin) {
      if (!this.eventHandle || this.eventHandle.isCancelled()) {
        const location: Location = null;
        this.receiveMessages(location);
      }
    }
  }

  private onNewConnection(connection: WebSocketConnection) {
    this.checkToResubscribe();
  }
}
