/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {EnterpriseServiceType} from '../../../datatype/business/service/EnterpriseServiceType'
import {EntityChannelType} from '../../../datatype/entity/channel/EntityChannelType'
import {EntityInfo} from '../../../datatype/entity/EntityInfo'
import {EntityMessageStatus} from './EntityMessageStatus'
import {EntityMessage} from './EntityMessage'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {MessageType} from '../../MessageContentType'
import {MessageResponseType} from '@/services/message'
import {MessageRoleType} from '../../api/MessageRoleType'
import {RequestStatusType} from '../../../datatype/request/RequestStatusType'
import {Serializer} from '../../../serialize/Serializer'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractInboxMessageWrapper extends AbstractSerializable implements EntityMessage  {
    receiverIndex: number;
    read: boolean;
    message: EntityMessage;
    mediumEntityInfo: EntityInfo;

    constructor() {
    	super();
    }

    public getReceiverIndex(): number {
    	return this.receiverIndex;
    }

    public setReceiverIndex(receiverIndex: number) {
    	this.receiverIndex = receiverIndex;
    }

    public isRead(): boolean {
    	return this.read;
    }

    public setRead(read: boolean) {
    	this.read = read;
    }

    public getMessage(): EntityMessage {
    	return this.message;
    }

    public setMessage(message: EntityMessage) {
    	this.message = message;
    }

    public getMediumEntityInfo(): EntityInfo {
    	return this.mediumEntityInfo;
    }

    public setMediumEntityInfo(mediumEntityInfo: EntityInfo) {
    	this.mediumEntityInfo = mediumEntityInfo;
    }

    abstract isMultipleResponse(): boolean;

    abstract isResponseRequired(): boolean;

    abstract supportResend(): boolean;

    abstract getResponseType(): MessageResponseType;

    abstract getContentType(): MessageType;

    abstract getCreateTime(): (number | string);

    abstract setCreateTime(arg0: (number | string | null));

    abstract isDisplayable(): boolean;

    abstract getServiceType(): EnterpriseServiceType;

    abstract getServiceRequestStatus(): RequestStatusType;

    abstract getRoleType(): MessageRoleType;

    abstract getMessageCore(): EntityMessage;

    abstract getStatus(): EntityMessageStatus;

    abstract getCorrelationId(): (number | string);

    abstract getId(): (number | string);

    abstract getServiceRequestId(): (number | string);

    abstract getMessageContent(): any;

    abstract getDataText(): string;

    abstract getReferenceKey(): string;

    abstract getServiceClassifier(): string;

    abstract getServiceClassifierDisplayName(): string;

    abstract getServiceClassifierForPlatform(): string;

    abstract getServiceClassifierForProvider(): string;

    abstract validateMessage(): Set<string>;

    abstract setChannelReceiveTime(arg0: (number | string | null));

    abstract setCorrelationId(arg0: (number | string | null));

    abstract setId(arg0: (number | string | null));

    abstract setReferenceKey(arg0: (string | null));

    abstract setServiceClassifier(arg0: (string | null));

    abstract setServiceClassifierDisplayName(arg0: (string | null));

    abstract setServiceClassifierForPlatform(arg0: (string | null));

    abstract setServiceClassifierForProvider(arg0: (string | null));

    abstract setServiceRequestId(arg0: (number | string | null));

    abstract setServiceRequestStatus(arg0: (RequestStatusType | null));

    abstract setServiceType(arg0: (EnterpriseServiceType | null));

    abstract setStatus(arg0: (EntityMessageStatus | null));

    abstract getSenderEntityInfo(): EntityInfo;

    abstract getMediumChannelId(): EntityChannelType;

    abstract getTitle(): string;

    abstract getChannelReceiveTime(): (number | string);

    abstract getMediumEntityId(): (number | string);

    abstract getSenderEntityId(): (number | string);

    abstract setMediumEntityId(arg0: (number | string | null));

    abstract setSenderEntityInfo(arg0: (EntityInfo | null));

    abstract getRootMessageId(): any;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let receiverIndex = json["receiverIndex"];
        if (receiverIndex != null) {
            this.setReceiverIndex(receiverIndex);
        }
        let read = json["read"];
        if (read != null) {
            this.setRead(read);
        }
        let message = json["message"];
        if (message != null) {
            let convertedMessage0 = Serializer.fillFromJsonObjectWithTypeTag(message, "__type", factory);
            this.setMessage(convertedMessage0);
        }
        let mediumEntityInfo = json["mediumEntityInfo"];
        if (mediumEntityInfo != null) {
            let convertedMediumEntityInfo0 = Serializer.fillFromJsonObjectWithTypeTag(mediumEntityInfo, "__type", factory);
            this.setMediumEntityInfo(convertedMediumEntityInfo0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let receiverIndex = this.getReceiverIndex();
        if (receiverIndex != null) {
            json["receiverIndex"] = receiverIndex;
        }
        let read = this.isRead();
        if (read != null) {
            json["read"] = read;
        }
        let message = this.getMessage();
        if (message != null) {
            let convertedMessage0 = message.getJson(JsonTypeTagType.TYPE);
            json["message"] = convertedMessage0;
        }
        let mediumEntityInfo = this.getMediumEntityInfo();
        if (mediumEntityInfo != null) {
            let convertedMediumEntityInfo0 = mediumEntityInfo.getJson(JsonTypeTagType.TYPE);
            json["mediumEntityInfo"] = convertedMediumEntityInfo0;
        }

        if (includeTypeTag) {
            json["__type"] = "InboxMessageWrapper";
        }
    }


    public getTypeId(): string {
    	return "InboxMessageWrapper";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.message.entity.api.InboxMessageWrapper";
    }
}
