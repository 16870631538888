/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractMessage} from '../../api/AbstractMessage'
import {EnterpriseServiceType} from '../../../datatype/business/service/EnterpriseServiceType'
import {EntityChannelType} from '../../../datatype/entity/channel/EntityChannelType'
import {EntityInfo} from '../../../datatype/entity/EntityInfo'
import {EntityMessageStatus} from './EntityMessageStatus'
import {EntityMessage} from './EntityMessage'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {MessageType} from '../../MessageContentType'
import { MessageResponseType } from "../../MessageResponseType";
import {MessageRoleType} from '../../api/MessageRoleType'
import {RequestStatusType} from '../../../datatype/request/RequestStatusType'
import {Serializer} from '../../../serialize/Serializer'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractEntityMessage extends AbstractMessage implements EntityMessage  {
    id: (number | string);
    channelReceiveTime: (number | string);
    declare createTime: (number | string);
    correlationId: (number | string);
    rootMessageId: (number | string);
    senderEntityInfo: EntityInfo;
    mediumEntityId: (number | string);
    mediumChannelId: EntityChannelType;
    serviceRequestId: (number | string);
    serviceType: EnterpriseServiceType;
    serviceTypeName: string;
    serviceTypeDisplayName: string;
    serviceClassifier: string;
    serviceClassifierDisplayName: string;
    serviceClassifierForProvider: string;
    serviceClassifierForPlatform: string;
    serviceRequestStatus: RequestStatusType;
    referenceKey: string;
    status: EntityMessageStatus;

    constructor() {
    	super();
    }

    public getId(): (number | string) {
    	return this.id;
    }

    public setId(id: (number | string)) {
    	this.id = id;
    }

    public getChannelReceiveTime(): (number | string) {
    	return this.channelReceiveTime;
    }

    public setChannelReceiveTime(channelReceiveTime: (number | string)) {
    	this.channelReceiveTime = channelReceiveTime;
    }

    public getCorrelationId(): (number | string) {
    	return this.correlationId;
    }

    public setCorrelationId(correlationId: (number | string)) {
    	this.correlationId = correlationId;
    }

    public getRootMessageId(): (number | string) {
    	return this.rootMessageId;
    }

    public setRootMessageId(rootMessageId: (number | string)) {
    	this.rootMessageId = rootMessageId;
    }

    public getSenderEntityInfo(): EntityInfo {
    	return this.senderEntityInfo;
    }

    public setSenderEntityInfo(senderEntityInfo: EntityInfo) {
    	this.senderEntityInfo = senderEntityInfo;
    }

    public getMediumEntityId(): (number | string) {
    	return this.mediumEntityId;
    }

    public setMediumEntityId(mediumEntityId: (number | string)) {
    	this.mediumEntityId = mediumEntityId;
    }

    public getMediumChannelId(): EntityChannelType {
    	return this.mediumChannelId;
    }

    public setMediumChannelId(mediumChannelId: EntityChannelType) {
    	this.mediumChannelId = mediumChannelId;
    }

    public getServiceRequestId(): (number | string) {
    	return this.serviceRequestId;
    }

    public setServiceRequestId(serviceRequestId: (number | string)) {
    	this.serviceRequestId = serviceRequestId;
    }

    public getServiceType(): EnterpriseServiceType {
    	return this.serviceType;
    }

    public setServiceType(serviceType: EnterpriseServiceType) {
    	this.serviceType = serviceType;
    }

    public getServiceTypeName(): string {
    	return this.serviceTypeName;
    }

    public setServiceTypeName(serviceTypeName: string) {
    	this.serviceTypeName = serviceTypeName;
    }

    public getServiceTypeDisplayName(): string {
    	return this.serviceTypeDisplayName;
    }

    public setServiceTypeDisplayName(serviceTypeDisplayName: string) {
    	this.serviceTypeDisplayName = serviceTypeDisplayName;
    }

    public getServiceClassifier(): string {
    	return this.serviceClassifier;
    }

    public setServiceClassifier(serviceClassifier: string) {
    	this.serviceClassifier = serviceClassifier;
    }

    public getServiceClassifierDisplayName(): string {
    	return this.serviceClassifierDisplayName;
    }

    public setServiceClassifierDisplayName(serviceClassifierDisplayName: string) {
    	this.serviceClassifierDisplayName = serviceClassifierDisplayName;
    }

    public getServiceClassifierForProvider(): string {
    	return this.serviceClassifierForProvider;
    }

    public setServiceClassifierForProvider(serviceClassifierForProvider: string) {
    	this.serviceClassifierForProvider = serviceClassifierForProvider;
    }

    public getServiceClassifierForPlatform(): string {
    	return this.serviceClassifierForPlatform;
    }

    public setServiceClassifierForPlatform(serviceClassifierForPlatform: string) {
    	this.serviceClassifierForPlatform = serviceClassifierForPlatform;
    }

    public getServiceRequestStatus(): RequestStatusType {
    	return this.serviceRequestStatus;
    }

    public setServiceRequestStatus(serviceRequestStatus: RequestStatusType) {
    	this.serviceRequestStatus = serviceRequestStatus;
    }

    public getReferenceKey(): string {
    	return this.referenceKey;
    }

    public setReferenceKey(referenceKey: string) {
    	this.referenceKey = referenceKey;
    }

    public getStatus(): EntityMessageStatus {
    	return this.status;
    }

    public setStatus(status: EntityMessageStatus) {
    	this.status = status;
    }


    abstract isMultipleResponse(): boolean;

    abstract isResponseRequired(): boolean;

    abstract supportResend(): boolean;

    abstract getResponseType(): MessageResponseType;

    abstract getContentType(): MessageType;

    abstract isDisplayable(): boolean;

    abstract getRoleType(): MessageRoleType;

    abstract getMessageCore(): EntityMessage;

    abstract getReceiverIndex(): number;

    abstract getMessageContent(): any;

    abstract getDataText(): string;

    abstract validateMessage(): Set<string>;

    abstract getTitle(): string;

    abstract getSenderEntityId(): (number | string);

    abstract getTypeId(): string;

    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let id = json["id"];
        if (id != null) {
            this.setId(id);
        }
        let channelReceiveTime = json["channelReceiveTime"];
        if (channelReceiveTime != null) {
            this.setChannelReceiveTime(channelReceiveTime);
        }
        let correlationId = json["correlationId"];
        if (correlationId != null) {
            this.setCorrelationId(correlationId);
        }
        let rootMessageId = json["rootMessageId"];
        if (rootMessageId != null) {
            this.setRootMessageId(rootMessageId);
        }
        let senderEntityInfo = json["senderEntityInfo"];
        if (senderEntityInfo != null) {
            let convertedSenderEntityInfo0 = Serializer.fillFromJsonObjectWithTypeTag(senderEntityInfo, "__type", factory);
            this.setSenderEntityInfo(convertedSenderEntityInfo0);
        }
        let mediumEntityId = json["mediumEntityId"];
        if (mediumEntityId != null) {
            this.setMediumEntityId(mediumEntityId);
        }
        let mediumChannelId = json["mediumChannelId"];
        if (mediumChannelId != null) {
            let convertedMediumChannelId0 = EntityChannelType[<string>mediumChannelId];
            this.setMediumChannelId(convertedMediumChannelId0);
        }
        let serviceRequestId = json["serviceRequestId"];
        if (serviceRequestId != null) {
            this.setServiceRequestId(serviceRequestId);
        }
        let serviceType = json["serviceType"];
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[<string>serviceType];
            this.setServiceType(convertedServiceType0);
        }
        let serviceTypeName = json["serviceTypeName"];
        if (serviceTypeName != null) {
            this.setServiceTypeName(serviceTypeName);
        }
        let serviceTypeDisplayName = json["serviceTypeDisplayName"];
        if (serviceTypeDisplayName != null) {
            this.setServiceTypeDisplayName(serviceTypeDisplayName);
        }
        let serviceClassifier = json["serviceClassifier"];
        if (serviceClassifier != null) {
            this.setServiceClassifier(serviceClassifier);
        }
        let serviceClassifierDisplayName = json["serviceClassifierDisplayName"];
        if (serviceClassifierDisplayName != null) {
            this.setServiceClassifierDisplayName(serviceClassifierDisplayName);
        }
        let serviceClassifierForProvider = json["serviceClassifierForProvider"];
        if (serviceClassifierForProvider != null) {
            this.setServiceClassifierForProvider(serviceClassifierForProvider);
        }
        let serviceClassifierForPlatform = json["serviceClassifierForPlatform"];
        if (serviceClassifierForPlatform != null) {
            this.setServiceClassifierForPlatform(serviceClassifierForPlatform);
        }
        let serviceRequestStatus = json["serviceRequestStatus"];
        if (serviceRequestStatus != null) {
            let convertedServiceRequestStatus0 = RequestStatusType[<string>serviceRequestStatus];
            this.setServiceRequestStatus(convertedServiceRequestStatus0);
        }
        let referenceKey = json["referenceKey"];
        if (referenceKey != null) {
            this.setReferenceKey(referenceKey);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = EntityMessageStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let id = this.getId();
        if (id != null) {
            json["id"] = String(id);
        }
        let channelReceiveTime = this.getChannelReceiveTime();
        if (channelReceiveTime != null) {
            json["channelReceiveTime"] = String(channelReceiveTime);
        }
        let correlationId = this.getCorrelationId();
        if (correlationId != null) {
            json["correlationId"] = String(correlationId);
        }
        let rootMessageId = this.getRootMessageId();
        if (rootMessageId != null) {
            json["rootMessageId"] = String(rootMessageId);
        }
        let senderEntityInfo = this.getSenderEntityInfo();
        if (senderEntityInfo != null) {
            let convertedSenderEntityInfo0 = senderEntityInfo.getJson(JsonTypeTagType.TYPE);
            json["senderEntityInfo"] = convertedSenderEntityInfo0;
        }
        let mediumEntityId = this.getMediumEntityId();
        if (mediumEntityId != null) {
            json["mediumEntityId"] = String(mediumEntityId);
        }
        let mediumChannelId = this.getMediumChannelId();
        if (mediumChannelId != null) {
            let convertedMediumChannelId0 = EntityChannelType[mediumChannelId];
            json["mediumChannelId"] = convertedMediumChannelId0;
        }
        let serviceRequestId = this.getServiceRequestId();
        if (serviceRequestId != null) {
            json["serviceRequestId"] = String(serviceRequestId);
        }
        let serviceType = this.getServiceType();
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[serviceType];
            json["serviceType"] = convertedServiceType0;
        }
        let serviceTypeName = this.getServiceTypeName();
        if (serviceTypeName != null) {
            json["serviceTypeName"] = serviceTypeName;
        }
        let serviceTypeDisplayName = this.getServiceTypeDisplayName();
        if (serviceTypeDisplayName != null) {
            json["serviceTypeDisplayName"] = serviceTypeDisplayName;
        }
        let serviceClassifier = this.getServiceClassifier();
        if (serviceClassifier != null) {
            json["serviceClassifier"] = serviceClassifier;
        }
        let serviceClassifierDisplayName = this.getServiceClassifierDisplayName();
        if (serviceClassifierDisplayName != null) {
            json["serviceClassifierDisplayName"] = serviceClassifierDisplayName;
        }
        let serviceClassifierForProvider = this.getServiceClassifierForProvider();
        if (serviceClassifierForProvider != null) {
            json["serviceClassifierForProvider"] = serviceClassifierForProvider;
        }
        let serviceClassifierForPlatform = this.getServiceClassifierForPlatform();
        if (serviceClassifierForPlatform != null) {
            json["serviceClassifierForPlatform"] = serviceClassifierForPlatform;
        }
        let serviceRequestStatus = this.getServiceRequestStatus();
        if (serviceRequestStatus != null) {
            let convertedServiceRequestStatus0 = RequestStatusType[serviceRequestStatus];
            json["serviceRequestStatus"] = convertedServiceRequestStatus0;
        }
        let referenceKey = this.getReferenceKey();
        if (referenceKey != null) {
            json["referenceKey"] = referenceKey;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = EntityMessageStatus[status];
            json["status"] = convertedStatus0;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.message.entity.api.AbstractEntityMessage";
    }
}
